import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import '../styles/AuthForms.css';

// Function to get the API base URL
const getApiBaseUrl = () => {
    return window.location.hostname === 'localhost' ? 'http://localhost:8888/api' : 'https://ordfras.se/api';
};

const ResetPasswordForm = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [token, setToken] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [resetSuccess, setResetSuccess] = useState(false);
  
  const location = useLocation();
  const navigate = useNavigate();
  
  useEffect(() => {
    // Get token from URL query params
    const params = new URLSearchParams(location.search);
    const tokenParam = params.get('token');
    
    if (!tokenParam) {
      setError('Ogiltig återställningslänk');
      return;
    }
    
    setToken(tokenParam);
  }, [location]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    
    // Validate passwords match
    if (password !== confirmPassword) {
      setError('Lösenorden matchar inte');
      return;
    }
    
    // Validate password length
    if (password.length < 8) {
      setError('Lösenordet måste vara minst 8 tecken');
      return;
    }
    
    setIsLoading(true);

    try {
      const apiUrl = getApiBaseUrl(); // Get the API base URL
      const response = await fetch(`${apiUrl}/auth/reset-password.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          token,
          password,
          confirmPassword
        }),
      });

      const data = await response.json();

      if (!response.ok) {
        setError(data.error || 'Kunde inte återställa lösenordet');
        return;
      }

      setResetSuccess(true);
    } catch (error) {
      console.error('Reset password error:', error);
      setError('Ett fel uppstod. Försök igen senare.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleBackToLogin = () => {
    navigate('/');
  };

  if (!token && !resetSuccess) {
    return (
      <div className="auth-page">
        <div className="auth-container">
          <h2>Ogiltig återställningslänk</h2>
          <p>Länken som du använder för att återställa lösenordet är ogiltig eller har gått ut.</p>
          <button className="auth-btn" onClick={handleBackToLogin}>
            Tillbaka till startsidan
          </button>
        </div>
      </div>
    );
  }

  if (resetSuccess) {
    return (
      <div className="auth-page">
        <div className="auth-container">
          <h2>Lösenordet återställt</h2>
          <p>Ditt lösenord har återställts. Du kan nu logga in med ditt nya lösenord.</p>
          <button className="auth-btn" onClick={handleBackToLogin}>
            Gå till inloggning
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="auth-page">
      <div className="auth-container">
        <h2>Återställ lösenord</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="password">Nytt lösenord</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              minLength={8}
            />
            <small>Minst 8 tecken</small>
          </div>
          
          <div className="form-group">
            <label htmlFor="confirmPassword">Bekräfta lösenord</label>
            <input
              type="password"
              id="confirmPassword"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
              minLength={8}
            />
          </div>
          
          {error && <div className="error-message">{error}</div>}
          
          <button 
            type="submit" 
            className="auth-btn" 
            disabled={isLoading}
          >
            {isLoading ? 'Återställer...' : 'Återställ lösenord'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default ResetPasswordForm; 