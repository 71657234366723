import { makeAuthenticatedRequest } from './auth';
import { migrateLocalStorageToDatabase } from './migrateLocalStorage';

// Function to get the API base URL
const getApiBaseUrl = () => {
    return window.location.hostname === 'localhost' ? 'http://localhost:8888/api' : 'https://ordfras.se/api';
};

export const saveGameProgress = async (gameProgress) => {
  console.log('Raw gameProgress:', gameProgress);

  // Ensure grid is properly formatted
  const grid = gameProgress.grid ? JSON.stringify(gameProgress.grid) : '[]';
  console.log('Grid being sent:', grid);

  const userId = localStorage.getItem('ordfras_user_id');
  const dataToSend = {
    user_id: userId,
    game_date: gameProgress.date,
    moves_remaining: gameProgress.moves,
    reset_count: gameProgress.resetCount || 0,
    won: gameProgress.won ? 1 : 0,
    first_move_time: gameProgress.firstMoveTime,
    completion_time: gameProgress.completionTime,
    time_to_complete: gameProgress.timeToComplete,
    grid: grid // Use our formatted grid
  };

  console.log("Data being sent to save-progress:", dataToSend);

  try {
    const apiUrl = getApiBaseUrl(); // Get the API base URL
    const response = await fetch(`${apiUrl}/game/save-progress.php`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify(dataToSend)
    });

    const responseText = await response.text();
    console.log('API Response text:', responseText);

    if (!response.ok) {
      throw new Error(`Failed to save game progress: ${responseText}`);
    }

    return JSON.parse(responseText);
  } catch (error) {
    console.error('Error saving game progress:', error);
    throw error;
  }
};

export const migrateGamesForUser = async (userId) => {
  console.log('Attempting to migrate games for user:', userId);
  const games = migrateLocalStorageToDatabase();
  console.log('Games data:', games);

  try {
    const apiUrl = getApiBaseUrl(); // Get the API base URL
    const response = await fetch(`${apiUrl}/auth/migrate-games.php`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        user_id: userId,
        games: games
      })
    });

    const data = await response.json();
    console.log('Migration response:', data);

    // If we get a duplicate error, that's okay - it means games were already migrated
    if (data.error && data.message.includes('Integrity constraint violation')) {
      return { error: 'DUPLICATE_GAMES' };
    }

    return data;
  } catch (error) {
    console.error('Failed to migrate games:', error);
    throw error;
  }
};

export const loadGameProgress = async (gameDate) => {
  const userId = localStorage.getItem('ordfras_user_id');
  
  try {
    const apiUrl = getApiBaseUrl(); // Get the API base URL
    const response = await fetch(`${apiUrl}/game/load-progress.php?user_id=${userId}&game_date=${gameDate}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include'
    });

    const data = await response.json();
    if (!response.ok) {
      throw new Error(data.message || 'Failed to load game progress');
    }

    return data.gameProgress;
  } catch (error) {
    console.error('Error loading game progress:', error);
    return null;
  }
};

export const saveNickname = async (nickname) => {
  try {
    const userId = localStorage.getItem('ordfras_user_id');
    if (!userId) {
      console.error('No user ID found in localStorage');
      return { success: false, error: 'User not logged in' };
    }
    
    console.log('Saving nickname for userId:', userId, 'Nickname:', nickname);
    
    const apiUrl = getApiBaseUrl(); // Get the API base URL
    const response = await fetch(`${apiUrl}/auth/save-nickname.php`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify({
        user_id: userId,
        nickname: nickname
      })
    });

    const data = await response.json();
    console.log('Save nickname response:', data);
    
    if (!response.ok) {
      throw new Error(data.error || 'Failed to save nickname');
    }

    return data;
  } catch (error) {
    console.error('Error saving nickname:', error);
    return { success: false, error: error.message };
  }
};

export const checkNickname = async () => {
  try {
    const userId = localStorage.getItem('ordfras_user_id');
    if (!userId) {
      console.error('No user ID found in localStorage');
      return true; // Return true to prevent showing modal if no user ID
    }
    
    console.log('Checking nickname for userId:', userId);
    
    const apiUrl = getApiBaseUrl(); // Get the API base URL
    const response = await fetch(`${apiUrl}/auth/check-nickname.php?user_id=${userId}`, {
      credentials: 'include'
    });

    if (!response.ok) {
      console.error('Error checking nickname:', response.status);
      return true; // Return true to prevent showing modal on error
    }

    const data = await response.json();
    console.log('Nickname check response:', data);
    return data.hasNickname;
  } catch (error) {
    console.error('Error checking nickname:', error);
    return true; // Return true to prevent showing modal on error
  }
};

export const fetchLeaderboard = async () => {
  try {
    const apiUrl = getApiBaseUrl(); // Get the API base URL
    // Make a request to the get-scores.php endpoint which shows overall scores
    const response = await fetch(`${apiUrl}/game/get-scores.php`, {
      method: 'GET',
      credentials: 'include'
    });

    if (!response.ok) {
      throw new Error('Failed to fetch leaderboard');
    }

    const data = await response.json();
    return data; // This should return the array of user scores
  } catch (error) {
    console.error('Error fetching leaderboard:', error);
    return []; // Return empty array on error
  }
}; 