import React, { useState } from 'react';
import { FaArrowLeft } from 'react-icons/fa';
import '../styles/AuthForms.css';

const RegisterForm = ({ onBackClick }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [name, setName] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [registrationSuccess, setRegistrationSuccess] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    
    // Validate passwords match
    if (password !== confirmPassword) {
      setError('Lösenorden matchar inte');
      return;
    }
    
    setIsLoading(true);

    try {
      const response = await fetch('http://localhost:8888/api/auth/register.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          email,
          password,
          confirmPassword,
          name
        }),
      });

      const data = await response.json();

      if (!response.ok) {
        setError(data.error || 'Registreringen misslyckades');
        return;
      }

      // Show success message
      setRegistrationSuccess(true);
      
      // For development, log the verification URL
      if (data.verification_url) {
        console.log('Verification URL:', data.verification_url);
      }
    } catch (error) {
      console.error('Registration error:', error);
      setError('Ett fel uppstod. Försök igen senare.');
    } finally {
      setIsLoading(false);
    }
  };

  if (registrationSuccess) {
    return (
      <div className="auth-form-container">
        <div className="auth-header">
          <button className="back-btn" onClick={onBackClick}>
            <FaArrowLeft />
          </button>
          <h2>Registrering slutförd</h2>
        </div>
        <div className="success-message">
          <p>Ditt konto har skapats!</p>
          <p>Vi har skickat en verifieringslänk till din e-post.</p>
          <p>Vänligen klicka på länken för att aktivera ditt konto.</p>
        </div>
        <button className="auth-btn" onClick={onBackClick}>
          Tillbaka
        </button>
      </div>
    );
  }

  return (
    <div className="auth-form-container">
      <div className="auth-header">
        <button className="back-btn" onClick={onBackClick}>
          <FaArrowLeft />
        </button>
        <h2>Skapa nytt konto</h2>
      </div>
      
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="name">Namn (valfritt)</label>
          <input
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        
        <div className="form-group">
          <label htmlFor="email">E-post *</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        
        <div className="form-group">
          <label htmlFor="password">Lösenord *</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            minLength={8}
          />
          <small>Minst 8 tecken</small>
        </div>
        
        <div className="form-group">
          <label htmlFor="confirmPassword">Bekräfta lösenord *</label>
          <input
            type="password"
            id="confirmPassword"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
            minLength={8}
          />
        </div>
        
        {error && <div className="error-message">{error}</div>}
        
        <button 
          type="submit" 
          className="auth-btn" 
          disabled={isLoading}
        >
          {isLoading ? 'Skapar konto...' : 'Skapa konto'}
        </button>
      </form>
    </div>
  );
};

export default RegisterForm; 