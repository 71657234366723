import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import '../styles/AuthForms.css';

// Function to get the API base URL
const getApiBaseUrl = () => {
    return window.location.hostname === 'localhost' ? 'http://localhost:8888/api' : 'https://ordfras.se/api';
};

const EmailVerification = () => {
  const [isVerifying, setIsVerifying] = useState(true);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');
  
  const location = useLocation();
  const navigate = useNavigate();
  
  useEffect(() => {
    const verifyEmail = async () => {
      try {
        // Get token from URL query params
        const params = new URLSearchParams(location.search);
        const token = params.get('token');
        
        if (!token) {
          setError('Ogiltig verifieringslänk');
          setIsVerifying(false);
          return;
        }
        
        // Call API to verify email
        const apiUrl = getApiBaseUrl(); // Get the API base URL
        const response = await fetch(`${apiUrl}/auth/verify-email.php?token=${token}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        const data = await response.json();

        if (!response.ok) {
          setError(data.error || 'Kunde inte verifiera e-post');
          setIsVerifying(false);
          return;
        }

        // Just set success state - don't try to auto-login
        setSuccess(true);
        setIsVerifying(false);
      } catch (error) {
        console.error('Email verification error:', error);
        setError('Ett fel uppstod. Försök igen senare.');
        setIsVerifying(false);
      }
    };

    verifyEmail();
  }, [location]);

  const handleBackToLogin = () => {
    navigate('/');
  };

  return (
    <div className="auth-page">
      <div className="auth-container">
        <h2>E-postverifiering</h2>
        
        {isVerifying && (
          <div className="loading-spinner">
            <p>Verifierar din e-post...</p>
          </div>
        )}
        
        {!isVerifying && success && (
          <div className="success-message">
            <p>Din e-post har verifierats!</p>
            <p>Du kan nu logga in med ditt konto.</p>
            <button className="auth-btn" onClick={handleBackToLogin}>
              Gå till inloggning
            </button>
          </div>
        )}
        
        {!isVerifying && !success && (
          <div className="error-container">
            <p className="error-message">{error}</p>
            <button className="auth-btn" onClick={handleBackToLogin}>
              Tillbaka till startsidan
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default EmailVerification; 