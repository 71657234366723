import React, { useState, useContext } from 'react';
import { AuthContext } from '../App';
import LoginModal from './LoginModal';
import { FaSignOutAlt } from 'react-icons/fa';

// Function to get the API base URL
const getApiBaseUrl = () => {
    return window.location.hostname === 'localhost' ? 'http://localhost:8888/api' : 'https://ordfras.se/api';
};

const LoginButton = () => {
  const { isAuthenticated, user, logout } = useContext(AuthContext);
  const [showModal, setShowModal] = useState(false);

  const handleLoginWithGoogle = () => {
    // Redirect to Google login - using the correct endpoint
    const apiUrl = getApiBaseUrl(); // Get the API base URL
    window.location.href = `${apiUrl}/auth/google.php`;
  };

  const handleLogout = () => {
    logout();
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  return (
    <div>
      {isAuthenticated ? (
        <div className="tooltip-wrapper" data-tooltip="Logga ut">
          <button 
            className="icon-button"
            onClick={handleLogout}
          >
            <FaSignOutAlt size={22} />
          </button>
        </div>
      ) : (
        <div className="tooltip-wrapper" data-tooltip="Logga in">
          <button className="icon-button" onClick={toggleModal}>
            <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
              <path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
              <circle cx="8.5" cy="7" r="4"></circle>
              <path d="M20 8v6"></path>
              <path d="M23 11h-6"></path>
            </svg>
          </button>
        </div>
      )}

      <LoginModal 
        isOpen={showModal} 
        onClose={() => setShowModal(false)} 
        handleLoginWithGoogle={handleLoginWithGoogle} 
      />
    </div>
  );
};

export default LoginButton; 