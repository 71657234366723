import React from 'react';
import { Link } from 'react-router-dom';
import LoginButton from './LoginButton';
import { FaCrown } from 'react-icons/fa';

function Header() {
  return (
    <div className="header-container">
      <div className="title-row">
        <div className="header-left">
          <Link to="/topplista" className="crown-icon" title="Topplista">
            <FaCrown size={34} color="black" />
          </Link>
        </div>
        <Link to="/" className="title-link">
          <h1 className="main-title">ORDFRAS</h1>
        </Link>
        <div className="header-icons">
          <LoginButton />
          <div className="tooltip-wrapper" data-tooltip="Arkiv">
            <Link to="/arkiv" className="archive-link">
              <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
                <line x1="16" y1="2" x2="16" y2="6"></line>
                <line x1="8" y1="2" x2="8" y2="6"></line>
                <line x1="3" y1="10" x2="21" y2="10"></line>
              </svg>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header; 