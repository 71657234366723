import React, { useState, useContext } from 'react';
import { FaArrowLeft } from 'react-icons/fa';
import { AuthContext } from '../App';
import '../styles/AuthForms.css';
import { forceAuthentication } from '../utils/auth';

// Function to get the API base URL
const getApiBaseUrl = () => {
    return window.location.hostname === 'localhost' ? 'http://localhost:8888/api' : 'https://ordfras.se/api';
};

const LoginForm = ({ onBackClick, onClose }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [needsVerification, setNeedsVerification] = useState(false);
  const [showResetForm, setShowResetForm] = useState(false);
  const [resetEmail, setResetEmail] = useState('');
  const [resetSuccess, setResetSuccess] = useState(false);
  
  const { login } = useContext(AuthContext);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);

    try {
      const apiUrl = getApiBaseUrl(); // Get the API base URL
      const response = await fetch(`${apiUrl}/auth/email-login.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({ email, password }),
      });

      const data = await response.json();
      console.log('Login response data:', data);
      console.log('Token type:', typeof data.token, 'Token value:', data.token);

      if (!response.ok) {
        if (data.requiresVerification) {
          setNeedsVerification(true);
        } else {
          setError(data.error || 'Inloggningen misslyckades');
        }
        return;
      }

      // Store user info in localStorage
      localStorage.setItem('ordfras_user_id', data.user.id);
      localStorage.setItem('ordfras_user_email', data.user.email);
      if (data.user.name) localStorage.setItem('ordfras_user_name', data.user.name);
      if (data.user.nickname) localStorage.setItem('ordfras_user_nickname', data.user.nickname);
      
      // Log before login call
      console.log('About to call login with user:', data.user, 'and token:', data.token);

      // Update auth context
      login(data.user, data.token);
      
      // Log after login call
      console.log('Returned from login call, isAuthenticated should be updated');
      
      // If we're still here, try to log in manually
      if (!forceAuthentication(data.user)) {
        console.error('Failed to force authentication');
      } else {
        console.log('Forced authentication successful');
        onClose();
        window.location.reload();
      }
    } catch (error) {
      console.error('Login error:', error);
      setError('Ett fel uppstod. Försök igen senare.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);

    try {
      const apiUrl = getApiBaseUrl(); // Get the API base URL
      const response = await fetch(`${apiUrl}/auth/request-password-reset.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: resetEmail }),
      });

      const data = await response.json();

      if (data.success) {
        setResetSuccess(true);
      } else {
        setError(data.error || 'Kunde inte skicka återställningslänk');
      }
    } catch (error) {
      console.error('Reset password error:', error);
      setError('Ett fel uppstod. Försök igen senare.');
    } finally {
      setIsLoading(false);
    }
  };

  if (needsVerification) {
    return (
      <div className="auth-form-container">
        <div className="auth-header">
          <button className="back-btn" onClick={onBackClick}>
            <FaArrowLeft />
          </button>
          <h2>Verifiera din e-post</h2>
        </div>
        <div className="verification-message">
          <p>Din e-postadress har inte verifierats ännu.</p>
          <p>Vänligen kontrollera din e-post och klicka på verifieringslänken.</p>
          <p>Kontrollera även din skräppost om du inte hittar e-postmeddelandet.</p>
        </div>
        <button className="auth-btn" onClick={onBackClick}>
          Tillbaka
        </button>
      </div>
    );
  }

  if (showResetForm) {
    return (
      <div className="auth-form-container">
        <div className="auth-header">
          <button className="back-btn" onClick={() => setShowResetForm(false)}>
            <FaArrowLeft />
          </button>
          <h2>Återställ lösenord</h2>
        </div>
        
        {resetSuccess ? (
          <div className="success-message">
            <p>Om e-postadressen finns i vårt system har en återställningslänk skickats.</p>
            <p>Vänligen kontrollera din e-post.</p>
            <button 
              className="auth-btn" 
              onClick={() => setShowResetForm(false)}
            >
              Tillbaka till inloggning
            </button>
          </div>
        ) : (
          <form onSubmit={handleResetPassword}>
            <div className="form-group">
              <label htmlFor="resetEmail">E-post</label>
              <input
                type="email"
                id="resetEmail"
                value={resetEmail}
                onChange={(e) => setResetEmail(e.target.value)}
                required
              />
            </div>
            
            {error && <div className="error-message">{error}</div>}
            
            <button 
              type="submit" 
              className="auth-btn" 
              disabled={isLoading}
            >
              {isLoading ? 'Skickar...' : 'Skicka återställningslänk'}
            </button>
          </form>
        )}
      </div>
    );
  }

  return (
    <div className="auth-form-container">
      <div className="auth-header">
        <button className="back-btn" onClick={onBackClick}>
          <FaArrowLeft />
        </button>
        <h2>Logga in</h2>
      </div>
      
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="email">E-post</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        
        <div className="form-group">
          <label htmlFor="password">Lösenord</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            minLength={8}
          />
        </div>
        
        {error && <div className="error-message">{error}</div>}
        
        <button 
          type="submit" 
          className="auth-btn" 
          disabled={isLoading}
        >
          {isLoading ? 'Loggar in...' : 'Logga in'}
        </button>
        
        <button 
          type="button" 
          className="text-btn" 
          onClick={() => setShowResetForm(true)}
        >
          Glömt lösenord?
        </button>
      </form>
    </div>
  );
};

export default LoginForm; 