import React, { useState } from 'react';
import { FcGoogle } from 'react-icons/fc';
import { MdEmail } from 'react-icons/md';
import '../styles/LoginModal.css';
import LoginForm from './LoginForm';
import RegisterForm from './RegisterForm';

const LoginModal = ({ isOpen, onClose, handleLoginWithGoogle }) => {
  const [authMode, setAuthMode] = useState('options'); // options, email-login, register

  if (!isOpen) return null;

  const handleLoginClick = () => {
    setAuthMode('email-login');
  };

  const handleRegisterClick = () => {
    setAuthMode('register');
  };

  const handleBackToOptions = () => {
    setAuthMode('options');
  };

  const renderContent = () => {
    switch (authMode) {
      case 'email-login':
        return <LoginForm onBackClick={handleBackToOptions} onClose={onClose} />;
      case 'register':
        return <RegisterForm onBackClick={handleBackToOptions} onClose={onClose} />;
      default:
        return (
          <div className="login-options">
            <h2>Logga in eller skapa konto</h2>
            <p>Välj hur du vill fortsätta</p>
            
            <button className="login-option-btn google-btn" onClick={handleLoginWithGoogle}>
              <FcGoogle size={24} />
              <span>Fortsätt med Google</span>
            </button>
            
            <button className="login-option-btn email-btn" onClick={handleLoginClick}>
              <MdEmail size={24} />
              <span>Logga in med e-post</span>
            </button>
            
            <div className="login-divider">
              <span>Har du inget konto?</span>
            </div>
            
            <button className="register-btn" onClick={handleRegisterClick}>
              Skapa nytt konto
            </button>
          </div>
        );
    }
  };

  return (
    <div className="login-modal-overlay">
      <div className="login-modal">
        <button className="close-btn" onClick={onClose}>×</button>
        {renderContent()}
      </div>
    </div>
  );
};

export default LoginModal; 