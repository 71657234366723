import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import Header from './Header';
import { AuthContext } from '../App'; // Import AuthContext

// Constants for localStorage
const STORAGE_PREFIX = "ordfras_v2_";
const GAME_HISTORY_KEY = `${STORAGE_PREFIX}history`;

// Function to get the API base URL
const getApiBaseUrl = () => {
    return window.location.hostname === 'localhost' ? 'http://localhost:8888/api' : 'https://ordfras.se/api';
};

// Function to get game result - now with either database OR localStorage
const getGameResult = (date, dbGames = null, isUserLoggedIn = false) => {
    try {
        // If we have database results and user is logged in, only use database
        if (dbGames && isUserLoggedIn) {
            const dbResult = dbGames.find(item => item.date === date);
            console.log(`DB game result for ${date}:`, dbResult);
            return dbResult; // Can be null if game not found in DB
        }
        
        // If user is not logged in, use localStorage
        if (!isUserLoggedIn) {
            const history = JSON.parse(localStorage.getItem(GAME_HISTORY_KEY)) || [];
            const result = history.find(item => item.date === date);
            console.log(`LocalStorage game result for ${date}:`, result);
            return result;
        }
        
        // User is logged in but game not in database
        return null;
    } catch (error) {
        console.error('Error reading game history:', error);
        return null;
    }
};

const getResultEmoji = (result) => {
    if (!result) return "Ospelad";
    
    // Handle reset squares
    const resetCount = result.resetCount;
    let resetEmojis;
    
    if (resetCount === 0) {
        // No resets - show 5 green squares
        resetEmojis = "🟩".repeat(5);
    } else if (resetCount <= 5) {
        // 1-5 resets - show that many red squares followed by remaining green squares
        resetEmojis = "🟥".repeat(resetCount) + "🟩".repeat(5 - resetCount);
    } else {
        // More than 5 resets - show that many red squares plus one green square
        resetEmojis = "🟥".repeat(resetCount) + "🟩";
    }
    
    // Create star emojis based on movesRemaining
    const stars = result.movesRemaining === 0 ? "🙊" : "⭐".repeat(result.movesRemaining);
    
    // Return attempts and stars on separate lines
    return (
        <div className="result-container">
            <div className="attempts">{resetEmojis}</div>
            <div className="stars">{stars}</div>
        </div>
    );
};

// Function to fetch game results from the database
const fetchUserGames = async (userId) => {
    try {
        const apiUrl = getApiBaseUrl(); // Get the API base URL
        const response = await fetch(`${apiUrl}/game/get-user-games.php?user_id=${userId}`, {
            credentials: 'include'
        });
        
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        
        const data = await response.json();
        
        if (!data.success) {
            throw new Error(data.message || 'Failed to fetch user games');
        }
        
        console.log('Fetched user games from DB:', data.games);
        return data.games;
    } catch (error) {
        console.error('Error fetching user games:', error);
        return null;
    }
};

const Archive = () => {
    const [games, setGames] = useState([]);
    const [dbGames, setDbGames] = useState(null);
    const [loading, setLoading] = useState(true);
    const { isAuthenticated } = useContext(AuthContext);
    
    // Fetch database games if user is authenticated
    useEffect(() => {
        const loadDbGames = async () => {
            if (isAuthenticated) {
                const userId = localStorage.getItem('ordfras_user_id');
                if (userId) {
                    console.log('Fetching games for user ID:', userId);
                    const userGames = await fetchUserGames(userId);
                    setDbGames(userGames);
                }
            }
        };
        
        loadDbGames();
    }, [isAuthenticated]);

    // Load available games
    useEffect(() => {
        const loadGames = async () => {
            try {
                const startDate = new Date('2025-03-21');
                const today = new Date();
                today.setHours(23, 59, 59, 999);
                const endDate = new Date(Math.min(today.getTime() + 24*60*60*1000, new Date('2025-04-30').getTime()));
                const gameDates = [];

                // Add debug logging
                console.log('Start date:', startDate.toISOString());
                console.log('End date:', endDate.toISOString());

                for (let d = new Date(startDate); d <= endDate; d.setDate(d.getDate() + 1)) {
                    const dateStr = d.toISOString().split('T')[0];
                    
                    // Add debug logging
                    console.log('Checking date:', dateStr);
                    
                    try {
                        const gameModule = await import(`../gameData/${dateStr}.js`);
                        gameDates.push({
                            date: dateStr,
                            name: gameModule.name || null
                        });
                    } catch (error) {
                        console.log(`No game for date: ${dateStr}`);
                        continue;
                    }
                }

                // Add debug logging
                console.log('Final game dates:', gameDates);

                gameDates.sort((a, b) => a.date.localeCompare(b.date));
                setGames(gameDates);
            } catch (error) {
                console.error('Error loading games:', error);
            } finally {
                setLoading(false);
            }
        };

        loadGames();
    }, []);

    return (
        <div>
            <Header />
            <div className="title-container">
                <div className="today-date">Arkiv</div>
                <p className="game-instructions">Grattis, du har hittat arkivet. Nu kan du spela alla spelen.</p>
            </div>
            {loading ? (
                <div className="loading">Laddar arkiv...</div>
            ) : (
                <div className="archive-list">
                    {games.map((game) => (
                        <Link key={game.date} to={`/${game.date}/`} className="archive-item">
                            <div className="archive-date-section">
                                {game.name && <div className="game-name">{game.name}</div>}
                                <div className="archive-date">
                                    {new Date(game.date).toLocaleDateString('sv-SE', {
                                        year: 'numeric',
                                        month: 'long',
                                        day: 'numeric'
                                    })}
                                </div>
                            </div>
                            <div className="archive-result">
                                {getResultEmoji(getGameResult(game.date, dbGames, isAuthenticated))}
                            </div>
                        </Link>
                    ))}
                </div>
            )}
        </div>
    );
};

export default Archive; 