import React, { useState } from 'react';
import './NicknameModal.css';

const NicknameModal = ({ onSubmit, isOpen }) => {
  const [nickname, setNickname] = useState('');
  const [error, setError] = useState('');

  const validateNickname = (value) => {
    if (!value.trim()) {
      return 'Alias får inte vara tomt';
    }
    if (value.length > 15) {
      return 'Alias får max vara 15 tecken';
    }
    // Only allow letters, numbers, and common special characters
    if (!/^[a-zA-ZåäöÅÄÖ0-9\s-_]+$/.test(value)) {
      return 'Alias får endast innehålla bokstäver, siffror, mellanslag, - och _';
    }
    return '';
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationError = validateNickname(nickname);
    if (validationError) {
      setError(validationError);
      return;
    }
    onSubmit(nickname);
  };

  if (!isOpen) return null;

  return (
    <div className="nickname-modal-overlay">
      <div className="nickname-modal">
        <h2>Välkommen!</h2>
        <p>Ange ett alias (som blir publikt)</p>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            value={nickname}
            onChange={(e) => {
              setNickname(e.target.value);
              setError('');
            }}
            maxLength={15}
            placeholder="Skriv ditt alias här"
          />
          {error && <p className="error-message">{error}</p>}
          <button type="submit">Spara</button>
        </form>
      </div>
    </div>
  );
};

export default NicknameModal; 