// Utility functions for handling authentication

export const getToken = () => {
  return localStorage.getItem('ordfras_auth_token');
};

export const isAuthenticated = () => {
  try {
    const token = getToken();
    if (!token) return false;
    
    // Parse token and check expiration
    const parts = token.split('.');
    if (parts.length !== 3) return false;
    
    const payload = JSON.parse(atob(parts[1]));
    return payload.exp > Date.now() / 1000;
  } catch (e) {
    console.error('Error checking authentication:', e);
    return false;
  }
};

export const getUserInfo = () => {
  const token = getToken();
  if (!token) return null;
  
  try {
    const payload = JSON.parse(atob(token.split('.')[1]));
    return {
      email: payload.email,
      userId: payload.user_id
    };
  } catch (e) {
    return null;
  }
};

export const makeAuthenticatedRequest = async (url, options = {}) => {
  const token = getToken();
  
  if (!token) {
    throw new Error('No authentication token found');
  }

  const headers = {
    ...options.headers,
    'Authorization': `Bearer ${token}`
  };

  const response = await fetch(url, {
    ...options,
    credentials: 'include',
    headers
  });

  return response;
};

// Update the setAuthTokens function
export const setAuthTokens = (token) => {
  try {
    console.log('Setting auth tokens with token:', token);
    
    if (!token) {
      console.error('No token provided to setAuthTokens');
      return null;
    }
    
    const parts = token.split('.');
    if (parts.length !== 3) {
      console.error('Invalid token format - expected 3 parts but got:', parts.length);
      return null;
    }
    
    // Decode the payload
    const decoded = JSON.parse(atob(parts[1]));
    console.log('Decoded token payload:', decoded);
    
    // Store token and user ID in localStorage
    localStorage.setItem('ordfras_auth_token', token);
    localStorage.setItem('ordfras_user_id', decoded.user_id);
    
    // Return the decoded payload
    return decoded;
  } catch (error) {
    console.error('Error in setAuthTokens:', error);
    return null;
  }
};

// Add this function to auth.js
export const forceAuthentication = (user) => {
  try {
    // Create a simple token with user data
    const nowInSeconds = Math.floor(Date.now() / 1000);
    const payload = {
      user_id: user.id,
      name: user.name || '',
      email: user.email,
      exp: nowInSeconds + 86400 // 24 hours
    };
    
    // Create a simple JWT-like token (not secure, just for testing)
    const header = btoa(JSON.stringify({ typ: 'JWT', alg: 'HS256' }));
    const payloadBase64 = btoa(JSON.stringify(payload));
    const token = `${header}.${payloadBase64}.mocksignature`;
    
    // Store in localStorage
    localStorage.setItem('ordfras_user_id', user.id);
    localStorage.setItem('ordfras_auth_token', token);
    if (user.email) localStorage.setItem('ordfras_user_email', user.email);
    if (user.name) localStorage.setItem('ordfras_user_name', user.name);
    
    console.log('Forced authentication completed with user:', user);
    return true;
  } catch (error) {
    console.error('Error in forceAuthentication:', error);
    return false;
  }
}; 