import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import OrdfrasGame from "./OrdfrasGame";
import { isValidDateFormat } from "./utils/dateUtils";
import "./styles.css";
import Archive from './components/Archive';
import Header from './components/Header';
import { setAuthTokens } from './utils/auth';
import Scoreboard from './components/Scoreboard';
import { migrateGamesForUser } from './utils/gameApi';
import { migrateLocalStorageToDatabase } from './utils/migrateLocalStorage';
import NicknameModal from './components/NicknameModal';
import { checkNickname, saveNickname } from './utils/gameApi';
import EmailVerification from './components/EmailVerification';
import ResetPasswordForm from './components/ResetPasswordForm';

// Create AuthContext
export const AuthContext = React.createContext({
  isAuthenticated: false,
  token: null,
  user: null,
  login: () => {},
  logout: () => {},
});

// Create AuthProvider component
function AuthProvider({ children }) {
  const [auth, setAuth] = React.useState({
    isAuthenticated: false,
    token: null,
    user: null,
  });

  const login = (userData, optionalToken = null) => {
    console.log('Login started with userData:', userData, 'optionalToken:', optionalToken);
    
    // Get token from userData if not provided separately
    const token = optionalToken || (userData && userData.token ? userData.token : null);
    
    if (!token) {
      console.error('No token available for authentication');
      return;
    }
    
    console.log('About to call setAuthTokens with token:', token);
    
    try {
      // Save the token and get the payload
      const payload = setAuthTokens(token);
      console.log('Payload returned from setAuthTokens:', payload);
      
      if (payload) {
        // Ensure we have user data
       // const user = userData || { id: payload.user_id, email: payload.email, name: payload.name }; <- Old code that worked for email login but not google login.
       const user = { 
        id: payload.user_id,
        email: payload.email,
        name: payload.name
      };
        console.log('Setting auth state with user:', user);
        
        // Store in localStorage as well
        localStorage.setItem('ordfras_user_id', user.id);
        localStorage.setItem('ordfras_auth_token', token);
        if (user.email) {
          localStorage.setItem('ordfras_user_email', user.email);
          console.log('Stored user email in localStorage:', user.email);
        }
        if (user.name) {
          localStorage.setItem('ordfras_user_name', user.name);
          console.log('Stored user name in localStorage:', user.name);
        }
        
        // Update auth state
        setAuth({
          isAuthenticated: true,
          token,
          user,
        });
        
        console.log('Auth state updated:', {
          isAuthenticated: true,
          token,
          user,
        });
        return true;
      } else {
        console.error('Failed to set auth tokens, no payload returned');
        return false;
      }
    } catch (error) {
      console.error('Error in login function:', error);
      return false;
    }
  };

  const logout = () => {
    localStorage.removeItem('ordfras_auth_token');
    localStorage.removeItem('ordfras_user_id');
    setAuth({
      isAuthenticated: false,
      token: null,
      user: null,
    });
  };

  // Check for token on mount
  useEffect(() => {
    try {
      // First check localStorage
      const token = localStorage.getItem('ordfras_auth_token');
      const userId = localStorage.getItem('ordfras_user_id');
      const name = localStorage.getItem('ordfras_user_name');
      const email = localStorage.getItem('ordfras_user_email');
      
      if (token && userId) {
        console.log('Found token in localStorage, attempting to login');
        // Create minimal user object from localStorage
        const user = {
          id: userId,
          name: name || '',
          email: email || ''
        };
        
        login(user, token);
      } else {
        console.log('No valid auth data found in localStorage');
      }
    } catch (error) {
      console.error('Error during auth initialization:', error);
    }
  }, []);

  return (
    <AuthContext.Provider value={{ ...auth, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
}

// Token handler component
function TokenHandler() {
  const location = useLocation();
  const { login } = React.useContext(AuthContext);
  const [hasProcessedToken, setHasProcessedToken] = useState(false);
  
  useEffect(() => {
    // Only process the token once
    if (hasProcessedToken) {
      console.log("TokenHandler: Token has already been processed, skipping.");
      return;
    }
    
    const params = new URLSearchParams(location.search);
    const token = params.get('token');
    
    console.log("TokenHandler: Current location:", location);
    console.log("TokenHandler: Extracted token from URL:", token);
    
    if (token) {
      console.log("TokenHandler: Found token in URL, processing...");
      setHasProcessedToken(true); // Mark as processed
      
      // Create a minimal user object from the token
      const userData = {
        id: '', // We'll get this from the payload
        name: '',
        email: ''
      };
      
      console.log("TokenHandler: User data before login call:", userData);
      login(userData, token);
      console.log("TokenHandler: Called login with userData and token.");
      
      // Remove token from URL
      window.history.replaceState({}, document.title, window.location.pathname);
      console.log("TokenHandler: Token removed from URL.");
    } else {
      console.log("TokenHandler: No token found in URL.");
    }
  }, [location, login, hasProcessedToken]); // Add hasProcessedToken to dependencies

  return null;
}

// Game Container Component with Header
const GameContainer = ({ children }) => (
  <div className="App">
    <Header />
    {children}
  </div>
);

// Different Game Routes
const DailyGame = () => (
  <GameContainer>
    <OrdfrasGame />
  </GameContainer>
);

const DateSpecificGame = ({ date }) => {
  // Validate date format
  if (!isValidDateFormat(date)) {
    return <Navigate to="/dagens/" replace />;
  }
  
  return (
    <GameContainer>
      <OrdfrasGame date={date} />
    </GameContainer>
  );
};

function App() {
  const { isAuthenticated, user } = React.useContext(AuthContext);
  const [showNicknameModal, setShowNicknameModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    if (isAuthenticated && user) {
      console.log('Checking nickname for user:', user.id);
      checkNickname().then(hasNickname => {
        console.log('Nickname check result:', hasNickname);
        if (!hasNickname) {
          setShowNicknameModal(true);
        }
      });
    }
  }, [isAuthenticated, user]);

  const handleNicknameSubmit = async (nickname) => {
    try {
      if (!user) {
        console.error('No user found');
        return;
      }
      
      console.log('Submitting nickname:', nickname);
      
      const result = await saveNickname(nickname);
      console.log('Save nickname result:', result);
      
      if (result.success) {
        setShowNicknameModal(false);
        setSuccessMessage(`Välkommen ${nickname} till Ordfras! Dina spel sparas nu.`);
        
        // Update the user object with the new nickname
        const updatedUser = { ...user, nickname };
        // Update localStorage
        localStorage.setItem('ordfras_user_nickname', nickname);
        
        // Force refresh to update UI
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        alert(`Kunde inte spara alias: ${result.error || 'Okänt fel'}`);
      }
    } catch (error) {
      console.error('Failed to save nickname:', error);
      alert('Ett fel uppstod. Försök igen senare.');
    }
  };

  return (
    <Router>
      <TokenHandler />
      <div className="app">
        <Routes>
          {/* Redirect from home to dagens */}
          <Route path="/" element={<Navigate to="/dagens/" replace />} />
          
          {/* Daily game route */}
          <Route path="/dagens/" element={<DailyGame />} />
          
          {/* Date-specific game route */}
          <Route 
            path="/:date" 
            element={
              <DateRouter />
            } 
          />
          
          {/* Archive route */}
          <Route path="/arkiv/" element={<Archive />} />
          
          {/* Scoreboard route */}
          <Route path="/topplista" element={<Scoreboard />} />
          
          {/* Email verification route */}
          <Route path="/verify-email" element={<EmailVerification />} />
          
          {/* Reset password route */}
          <Route path="/reset-password" element={<ResetPasswordForm />} />
          
          {/* Fallback for invalid routes */}
          <Route path="*" element={<Navigate to="/dagens/" replace />} />
        </Routes>
      </div>
      {successMessage && <div className="success-message">{successMessage}</div>}
      <NicknameModal 
        isOpen={showNicknameModal} 
        onSubmit={handleNicknameSubmit}
      />
    </Router>
  );
}

// Special component to handle date parameter
const DateRouter = () => {
  // Get the date parameter from URL
  const pathname = window.location.pathname;
  const date = pathname.substring(1).replace(/\/$/, ''); // Remove starting / and trailing / if present
  
  return <DateSpecificGame date={date} />;
};

// Create a new root component
function Root() {
  return (
    <AuthProvider>
      <App />
    </AuthProvider>
  );
}

export default Root;
