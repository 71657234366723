import React, { useState, useEffect } from 'react';
import { useContext } from 'react';
import { AuthContext } from '../App';
import '../styles/Scoreboard.css';
import { fetchLeaderboard } from '../utils/gameApi';
import Header from './Header';

const Scoreboard = () => {
  const [scores, setScores] = useState([]);
  const [loading, setLoading] = useState(true);
  const { isAuthenticated } = useContext(AuthContext);

  useEffect(() => {
    const loadScores = async () => {
      try {
        const data = await fetchLeaderboard();
        setScores(data);
      } catch (error) {
        console.error('Error fetching scores:', error);
      } finally {
        setLoading(false);
      }
    };

    loadScores();
  }, []);

  if (loading) {
    return <div>Laddar...</div>;
  }

  return (
    <div>
      <Header />
      <div className="title-container">
        <div className="today-date">Topplista</div>
        <p className="game-instructions">
          Logga in för att tävla mot andra
        </p>
      </div>

      {scores.length > 0 ? (
        <div className="scoreboard-wrapper">
          <table className="scoreboard-table">
            <thead>
              <tr>
                <th className="align-left">#</th>
                <th className="align-left">Namn</th>
                <th className="align-right">
                  <span className="align-right">🏁</span>
                </th>
                <th className="align-right">
                  <span className="align-right">⭐</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {scores.map((score, index) => (
                <tr key={score.user_id}>
                  <td className="align-left">{index + 1}</td>
                  <td className="align-left">{score.display_name}</td>
                  <td className="align-right">{score.games_won}</td>
                  <td className="align-right">{score.total_moves_remaining}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <p>Inga resultat än</p>
      )}
    </div>
  );
};

export default Scoreboard; 